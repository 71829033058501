import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import jobsData from "../data/data.json";

const SearchResults = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const titleFilter = searchParams.get("title") || "";
  const locationFilter = searchParams.get("location") || "";

  // Filter jobs based on the query parameters
  const filteredJobs = jobsData.filter(
    (job) =>
      job.title.toLowerCase().includes(titleFilter.toLowerCase()) &&
      job.location.toLowerCase().includes(locationFilter.toLowerCase())
  );

  // Default to the first job in the filtered list
  const [selectedJob, setSelectedJob] = useState(filteredJobs[0]);

  return (
    <div className="bg-gray-100 min-h-screen p-4">
      <div className="max-w-7xl mx-auto">
        {/* Main Content */}
        <div className="flex space-x-6">
          {/* Left Column: Job Cards */}
          <div className="w-1/2 bg-white shadow rounded-lg p-4 overflow-y-auto max-h-[70vh]">
            <h2 className="text-lg font-semibold mb-4">
              Search Results for "{titleFilter}" in "{locationFilter}"
            </h2>
            {filteredJobs.length > 0 ? (
              filteredJobs.map((job) => (
                <div
                  key={job.id}
                  className={`p-4 rounded-lg shadow-sm mb-4 cursor-pointer ${
                    selectedJob?.id === job.id
                      ? "border-2 border-blue-600"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => setSelectedJob(job)}
                >
                  <h3 className="text-lg font-bold">{job.title}</h3>
                  <p className="text-sm text-gray-600">
                    {job.company} • {job.location}
                  </p>
                  <div className="flex flex-wrap gap-2 mt-2">
                    {job.tags.map((tag, index) => (
                      <span
                        key={index}
                        className="bg-gray-200 text-sm text-gray-700 px-2 py-1 rounded"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                  <p className="text-sm text-gray-500 mt-3">{job.active}</p>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No jobs match your search criteria.</p>
            )}
          </div>

          {/* Right Column: Job Details */}
          <div className="w-1/2 bg-white shadow rounded-lg overflow-y-auto max-h-[70vh] relative">
            {selectedJob ? (
              <>
                {/* Sticky Header Section */}
                <div className="sticky top-0 bg-white z-10 border-b px-6 py-4">
                  <h2 className="text-xl font-bold">{selectedJob.title}</h2>
                  <p className="text-gray-600">
                    <span className="text-blue-600">{selectedJob.company}</span> •{" "}
                    {selectedJob.location}
                  </p>
                  <p className="text-gray-800 font-medium">
                    {selectedJob.pay} - {selectedJob.type}
                  </p>
                  <div className="flex space-x-2 mt-2">
                    <button className="bg-blue-600 text-white font-medium px-4 py-2 rounded hover:bg-blue-700">
                      Apply now
                    </button>
                  </div>
                </div>

                {/* Scrollable Content Section */}
                <div className="px-6 py-4">
                  <h3 className="text-lg font-semibold mb-2">Location</h3>
                  <p className="text-gray-500 mb-4">{selectedJob.locationDetails}</p>
                  <h3 className="text-lg font-semibold mb-2">Full job description</h3>
                  <p className="text-gray-700 mb-2">{selectedJob.description}</p>
                </div>
              </>
            ) : (
              <p className="text-gray-500 text-center py-6">
                Select a job to view details
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
